import React, { useState, useEffect } from "react"
import { Box, Typography, Button, CircularProgress, Accordion, AccordionSummary, AccordionDetails, Link } from "@mui/material";
import Layout from "../components/Layout"
import { MetaData } from "../components/common/meta";
import { PageProps } from "gatsby";

import { OutboundLink } from "gatsby-plugin-google-gtag";
import { StaticImage } from "gatsby-plugin-image"
import Arrow from "../images/arrow-right.inline.svg";
import sideBrand2 from "../images/pitLine2.svg";

const departmentList = {
  borderTop: "1px solid #4B608D",
  m: "20px auto",
  width: "calc(100% - 40px)",
  position: "relative",
};
const departmentListItem = {
  display: { xs: "block", md: "flex" },
  justifyContent: "space-between",
  padding: { xs: "20px", md: "30px 20px" },
  borderBottom: "1px solid #4B608D",
  borderRadius: 0,
  textAlign: { xs: "left", md: "initial" },
  width: "100%",
  "&:hover": {
    background: "#0C1F47",
  },
};

interface Location {
  name: string;
}
interface Job {
  title: string;
  absolute_url: string;
  location: Location;
}

interface Department {
  name: string;
  jobs: Array<Job>;
}

import bg from "../images/career/bg.png";

const careers = ({ location }: PageProps) => {
  const [data, setData] = useState<{ departments: Array<Department> } | null>(
    null
  );
  const jObsUrl =
    "https://boards-api.greenhouse.io/v1/boards/jumpcrypto/departments";
  useEffect(() => {
    fetch(jObsUrl)
      .then((res) => res.json())
      .then((data) => {
        setData(data);
      });
  }, []);
  console.log(data);

  return (
    <Layout>
      <MetaData title="Careers" location={location} type="website" />
      <Box
        component="span"
        sx={{
          position: "absolute",
          left: "65px",
          top: "200px",
          height: "calc(100% - 415px)",
          width: 0,
          borderLeft: "1px solid #4B608D",
          zIndex: 2,
          display: { xs: "none", xl: "block" },
          "&:before": {
            content: '""',
            position: "absolute",
            top: "-5px",
            left: "-8px",
            width: "15px",
            height: "5px",
            backgroundColor: "#1CE7C2",
          },
        }}
      />
      <Box component="div" sx={{ position: "relative" }}>
        <Box
          component="img"
          src={bg}
          alt=""
          sx={{
            width: "100%",
            transform: "scale(1.05)",
            zIndex: -2,
            opacity: 0.8,
            position: "absolute",
            left: 0,
            top: 0,
          }}
        />
        <Box
          component="div"
          sx={{
            maxWidth: 800,
            mx: "auto",
            pt: "20vw",
            pb: { xs: 6, md: 14 },
            textAlign: "center",
          }}
        >
          <Typography sx={{ mb: 3 }} variant="h1">
            <Box component="span" sx={{ color: "#1CE7C2" }}>
              Wanted:
            </Box>{" "}
            Builders, Makers, Thinkers, Doers.{" "}
          </Typography>
          <Box component="div" sx={{ maxWidth: 650, mx: "auto" }}>
            <Typography>See below for a list of openings.</Typography>
          </Box>
        </Box>
      </Box>

      <Box
        component="div"
        sx={{
          textAlign: "center",
          mb: { xs: 10, md: 10 },
          position: "relative",
          zIndex: 2,
        }}
      >
        <Typography sx={{ mb: 3 }} variant="h2">
          Available{" "}
          <Box component="span" sx={{ color: "#1CE7C2" }}>
            positions_
          </Box>
        </Typography>

        {data ? (
          <Box
            component="div"
            sx={{ maxWidth: 1082, mx: "auto", mt: { xs: 1, md: 8 } }}
          >
            {data.departments
              .filter((department: Department) => department.jobs.length != 0)
              .map((item: Department, index: number) => (
                <Accordion key={index} expanded>
                  <AccordionSummary
                    sx={{
                      p: { xs: "20px", md: "28px 50px" },
                    }}
                    expandIcon=" "
                  >
                    <Typography variant="h4"> {item.name}</Typography>
                  </AccordionSummary>
                  <Box component="div" sx={departmentList}>
                    {item.jobs.map((job: Job) => (
                      <AccordionDetails key={job.absolute_url}>
                        <Button
                          component={OutboundLink}
                          href={job.absolute_url}
                          target="_blank"
                          sx={departmentListItem}
                        >
                          <Typography>{job.title}</Typography>
                          <Typography>{job.location.name}</Typography>
                        </Button>
                      </AccordionDetails>
                    ))}
                  </Box>
                </Accordion>
              ))}
            {data.departments
              .filter((department: Department) => department.jobs.length != 0).length === 0 && <Typography>No positions available.</Typography>}
          </Box>
        ) : (
          <Box component="div" sx={{ py: 10 }}>
            <CircularProgress />
          </Box>
        )}
      </Box>
      <Box component="div" sx={{ position: "relative" }}>
        <StaticImage
          src="../images/career/bricks.svg"
          alt=""
          placeholder="blurred"
          layout="fixed"
          style={{
            position: "absolute",
            left: "-100px",
            top: "-50px",
            zIndex: -1,
            pointerEvents: "none",
          }}
        />
      </Box>
      <Box
        component="div"
        sx={{
          position: "relative",
          pt: 20,
          "&:before": {
            content: '""',
            position: "absolute",
            width: "calc(50% - 220px)",
            left: 50,
            top: 100,
            height: 0,
            borderTop: "1px solid #4B608D",
            display: { xs: "none", xl: "block" },
          },
          "&:after": {
            content: `url(${sideBrand2})`,
            position: "absolute",
            left: "calc(50% - 400px)",
            top: 98,
            display: { xs: "none", xl: "block" },
            zIndex: -1,
          },
        }}
      />
      <Box component="div" sx={{ textAlign: "center", mb: { xs: 8, md: 14 } }}>
        <Typography sx={{ mb: 3 }} variant="h2">
          Our friends{" "}
          <Box component="span" sx={{ color: "#1CE7C2" }}>
            are hiring_
          </Box>
        </Typography>
        <Typography sx={{ maxWidth: 440, mx: "auto" }}>
          Discover exciting career opportunities with our fellow innovators and
          partners in Web3.
        </Typography>
        <Button
          component={OutboundLink}
          href="http://portfoliojobs.jumpcrypto.com/"
          variant="outlined"
          target="_blank"
          endIcon={<Arrow />}
          sx={{ mt: 4 }}
        >
          .Explore Job Openings{" "}
        </Button>
      </Box>

    </Layout>
  );
};




export default careers